import React from "react";

function NumberContactWidget() {
  return (
    <div className="contact-widget">
      <div className="widget-content">
        <div className="contact-title">
          <span>Reach Out To Us</span>
          <h3>Call Us</h3>
        <span>Mon-Fri 8am-5pm</span>
        </div>
        {/* 
        <div className="newsletter-img">
          <img src={newsletterImg1} alt="Image" />
        </div> */}
        <button className="sass-contact-btn btn" type="submit">
          + 1 (437) 677 9936
        </button>

        <div className="email-title">
          <h3>Email Us</h3>
        </div>

        <button className="sass-contact-btn btn" type="submit">
          Contact@CareConnect.com
        </button>

        <div className="contact-form"></div>
      </div>
    </div>
  );
}

export default NumberContactWidget;
