import * as React from "react";
import "../styles/navbar.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../images/holding_hands.png";
import { Link } from "react-scroll";

const NavbarComp = () => {
  const [menu, setMenu] = React.useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  return (
    <Navbar collapseOnSelect expand="lg" className="nav">
      <Container>
        <Navbar.Brand href="/">
          {" "}
          <img
            src={logo}
            width="60"
            height="60"
            className="d-inline-block align-top"
            alt="CuraConnect logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <nav id="navbar" class="navMenu">
              <Link
                activeClass="active"
                to="landing"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <a href="/">About Us</a>
              </Link>
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <a href="/">Services</a>
              </Link>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                <a href="/">Contact Us</a>
              </Link>
              <div class="dot"></div>
            </nav>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComp;
