import React, { useEffect } from "react";
import Contact from "../components/Contact";
import Layout from "../components/Layout/layout";
import Aos from "aos";
import "aos/dist/aos.css";
import Seo from "../components/Layout/seo";
import MainBanner from "../components/MainBanner";
import NavbarComp from "../components/Navbar";
import Services from "../components/Services";
import Footer from "../components/Footer";

const HomeView = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Layout>
      <Seo title="Felo Staffing Services" />
      <NavbarComp />
      <div data-aos="fade" data-aos-once="false">
        <section id="landing">
          <MainBanner />
        </section>
      </div>
      <div data-aos="fade" data-aos-once="false">
        <section id="landing">
          <Services />
        </section>
      </div>

      <div data-aos="fade" data-aos-once="false">
        <section id="landing">
          <Contact />
        </section>
      </div>

      <Footer />
    </Layout>
  );
};
export default HomeView;
