import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/responsive.scss";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import HomeView from "./pages/HomeView";
import AboutUsView from "./pages/AboutUsView";
import ErrorView from "./pages/ErrorView";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeView />,
    errorElement: <ErrorView />,
  },
  {
    path: "/aboutus",
    element: <AboutUsView />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
