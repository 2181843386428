import * as React from "react";

import newsletterImg1 from "../images/newsletter-img.png";
import shape6 from "../images/shape/shape-6.png";
import shape7 from "../images/shape/shape-7.png";
import ContactWidget from "./widgets/ContactWidget";
import NewsletterWidget from "./widgets/NewsletterWidget";
import NumberContactWidget from "./widgets/NumberContactWidget";

const Contact = () => {
  return (
    <>
      <div id="contact" className="contact-and-subscribe-area pt-100 pb-70">
        <div className="container">
          <div className="sass-section-title">
            <span>Contact Us</span>
            <h2>Let's Get In Touch</h2>
          </div>

          <div className="row">
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
              data-aos-once="true"
            >
              <ContactWidget />
            </div>

            {/* <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <NewsletterWidget />
            </div> */}
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <NumberContactWidget />
            </div>
          </div>
        </div>

        <div className="shape">
          <img src={shape6} className="contact-shape-1" alt="Image" />
          <img src={shape7} className="contact-shape-2" alt="Image" />
        </div>
      </div>
    </>
  );
};

export default Contact;
