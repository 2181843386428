import React from "react";

function ContactWidget() {
  return (
    <div className="contact-widget">
      <div className="widget-content">
        <div className="contact-title">
          <span>Contact</span>
          <h3>Write Message</h3>
        </div>

        <div className="contact-form">
          <form id="contactForm" onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Your name"
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12 number-email-block">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    size="30"
                    className="form-control"
                    placeholder="Email Address"
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="number"
                    name="Number"
                    id="number"
                    className="form-control"
                    placeholder="Phone Number"
                    required
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    cols="30"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <button type="submit" className="sass-default-btn btn">
                  <span>Send message</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactWidget;
