import * as React from "react";

const AboutUsView = () => {
  return (
    <>
      <h1>About us</h1>
    </>
  );
};

export default AboutUsView;
