import * as React from "react";

const Services = () => {
  return (
    <>
      <div id="services" className="solution-area pt-100 pb-70">
        <div className="container">
          <div className="sass-section-title">
            <span>Our Services</span>
            <h2>We're Here to Help</h2>
          </div>

          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <div className="sass-solution-single-card">
                <div className="solution-content">
                  <div className="icon">
                    <i class="fi fi-rr-user"></i>
                  </div>
                  <h3>Find Your Next Career</h3>
                  <p>
                    With healthcare jobs on the rise, recruiting and finding
                    your next career has become paramount. <br />
                    Our full staff of specialized recruiters have years of
                    industry expertise and connections, allowing us to help you
                    find the job that allows you to positively impact patients
                    and increase outcomes.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="400"
              data-aos-once="true"
            >
              <div className="sass-solution-single-card">
                <div className="solution-content">
                  <div className="icon">
                    <i class="fi fi-sr-users-alt"></i>
                  </div>
                  <h3>Grow Your staff</h3>
                  <p>
                    Building your healthcare workforce can be cumbersome. That’s
                    why at FSS we work on a partner basis, meaning your success
                    is our success. We screen candidates prior to you ever
                    seeing them to make sure that the talent we present you is
                    in fact the individuals you need in order to do the job
                    required.
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
              data-aos-once="true"
            >
              <div className="sass-solution-single-card">
                <div className="solution-content">
                  <div className="icon">
                    <i className="flaticon-file"></i>
                  </div>
                  <h3></h3>
                  <p>
                  
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
